import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
// import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Logo from '@material-ui/icons/GolfCourse';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },
  toolbar: {
    minHeight: 64
  },
  logo: {
    "& path": {
      fill: '#fff'
    },
    "& circle": {
      fill: 'yellow'
    }
  }
}));

function TopBar({
  className,
  onMobileNavOpen,
  ...rest
}) {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        {/* <Hidden lgUp> */}
        {/* </Hidden> */}
        {/* <Hidden mdDown>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Hidden> */}
        <RouterLink to="/">
          <SvgIcon fontSize="large">
            <Logo className={classes.logo}/>
          </SvgIcon>
        </RouterLink>
        <Box
          ml={2}
          flexGrow={1}
        />
        {/*<Search />*/}
        {/*<Contacts />*/}
        {/*<Notifications />*/}
        {/* <Settings /> */}
        {/* <Box ml={2}>
          <Account />
        </Box> */}
        <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
