import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';


function DashboardLayout({ children }) {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const hideNavbar = useMediaQuery('(max-width: 1250px)');

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%'
    },
    wrapper: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      paddingTop: 64
    },
    contentContainer: {
      display: 'flex',
      // flex: '1 1 auto',
      overflow: 'hidden',
      width: `calc(100% - ${!hideNavbar ? 256 : 0}px)`
    },
    content: {
      flex: '1 1 auto',
      height: '100%',
      overflow: 'auto'
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;
