import axios from 'src/utils/axios';


export const COMPETITIONS_REQUEST = '@competitions/competitions-request';
export const COMPETITIONS_SUCCESS = '@competitions/competitions-success';
export const COMPETITIONS_FAILURE = '@competitions/competitions-failure';
export const COMPETITIONS_AFTER_LOGIN = '@competitions/competitions-after-login';
export const COMPETITIONS_UNMOUNT = '@competitions/competitions-unmount';
export const GET_COMPETITION_BY_ID = '@competitions/get-competition-by-id';
export const GET_COMPETITION_REQUEST = '@competitions/get-competition-request';
export const GET_COMPETITION_SUCCESS = '@competitions/get-competition-success';
export const GET_COMPETITION_FAILURE = '@competitions/get-competition-failure';

export const getCompetitions = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: COMPETITIONS_REQUEST });

      const res = await axios.get('/api/competitions');
      dispatch({ type: COMPETITIONS_SUCCESS, payload: res.data.competitions });
      
      return res.data.competitions;
    } catch (error) {
      dispatch({ type: COMPETITIONS_FAILURE });
      throw error;
    }
  }
};

export const getCompetitionsAfterLogin = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: COMPETITIONS_REQUEST });

      const res = await axios.get('/api/competitions');
      dispatch({ type: COMPETITIONS_AFTER_LOGIN, payload: res.data.competitions });

      return res.data.competitions;
    } catch (error) {
      dispatch({ type: COMPETITIONS_FAILURE });
      throw error;
    }
  }
};

export const competitionsUnmount = () => {
  return { type: COMPETITIONS_UNMOUNT };
};

export const getCompetitionById = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COMPETITION_REQUEST });
  
      const res = await axios.get(`/api/competitions/${id}`);
      dispatch({ type: GET_COMPETITION_SUCCESS, payload: res.data.competition });

      return res.data.competition;
    } catch (error) {
      dispatch({ type: GET_COMPETITION_FAILURE });
    }
  }
};

// Edit competition ....etc
