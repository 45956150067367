import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = (username, password) => new Promise((resolve, reject) => {
    axios.post('/api/account/login', { username, password })
      .then((response) => {
        if (response.data.user) {
          this.setSession(response.data.accessToken);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  loginInWithToken = () => new Promise((resolve, reject) => {
    axios.get('/api/account/me')
      .then((response) => {
        if (response.data.user) {
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  logout = () => {
    this.setSession(null);
  }

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  }

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }

  isAuthenticated = () => !!this.getAccessToken()

  register = (data) => new Promise((resolve, reject) => {
    axios.post('/api/account/register', data)
      .then(response => {
        if (response.data.user) {
          this.setSession(response.data.accessToken);
          resolve(response.data.user)
        } else {
          reject(response.data.error)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })

  changePassword = (data) => new Promise((resolve, reject) => {
    axios.post('/api/account/change-password', data)
      .then(response => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data.error)
        }
      })
      .catch(error => {
        reject(error);
      })
  });

  requestChangePasswordEmail = (data) => new Promise((resolve, reject) => {
    axios.post('/api/account/forgot-password', data)
      .then(response => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

  resendVerificationEmail = () => new Promise((resolve, reject) => {
    axios.get('/api/account/resend-verification')
      .then(response => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

  verifyEmail = (access) => new Promise((resolve, reject) => {
    axios.get(`/api/account/verify-email?access=${access}`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error);
      });
  });

  uploadAvatar = (data, onProgress) => new Promise((resolve, reject) => {
    axios.post(
      '/api/account/upload-avatar', 
      data, 
      {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: data => onProgress && onProgress(data)
      },
      )
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error);
      });
  });

  deleteAvatar = () => new Promise((resolve, reject) => {
    axios.delete('/api/account/delete-avatar')
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error);
      });
  });
}

const authService = new AuthService();

export default authService;
