/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Link,
  List,
  ListSubheader,
  Button,
  Typography,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import CompetitionIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Calendar as CalendarIcon,
  UserCheck as UsersIcon,
  Users as PlayersIcon,
  Home as ClubsIcon,
  Award as SeasonIcon
} from 'react-feather';
import { useSnackbar } from 'notistack';
import NavItem from './NavItem';
import { logout } from 'src/actions/accountActions';
import { useHistory } from 'react-router';
import { useIsEmailVerified } from 'src/utils/account';


const navConfig = [
  {
    subheader: '',
    items: [
      {
        title: 'Users',
        icon: UsersIcon,
        href: '/app/users',
        role_id: 1
      },
      {
        title: 'Players',
        icon: PlayersIcon,
        href: '/app/players',
        role_id: 1
      },
      {
        title: 'Clubs',
        icon: ClubsIcon,
        href: '/app/clubs',
        role_id: 1
      },
      {
        title: 'Pro Events',
        icon: CalendarIcon,
        href: '/app/events',
        role_id: 5
      },
      {
        title: 'Competitions',
        icon: CompetitionIcon,
        href: '/app/competitions',
        role_id: 5
      },
      {
        title: 'Seasons',
        icon: SeasonIcon,
        href: '/app/seasons',
        role_id: 5
      }
    ]
  },
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        role_id={item.role_id}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        role_id={item.role_id}
        title={item.title}
      />
    );
  }

  return acc;
}

function NavBar({ openMobile, onMobileClose, }) {
  const location = useLocation();
  const { user } = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar();
  const temporaryNavbar = useMediaQuery('(max-width: 1250px)');
  const { email_v, golflink_v } = useIsEmailVerified();

  const useStyles = makeStyles(() => ({
    mobileDrawer: {
      width: 256,
      top: `${!temporaryNavbar ? 64 : 0}px`
    },
    desktopDrawer: {
      width: 256,
      top: 64,
      height: 'calc(100% - 64px)'
    },
    avatar: {
      cursor: 'pointer',
      width: 64,
      height: 64
    },
    logout: {
      textTransform: 'capitalize',
      paddingLeft: '1rem'
    }
  }));

  const classes = useStyles();

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      history.push('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {/* <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden> */}
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <Avatar
              alt="User"
              className={classes.avatar}
              src={`${user.avatar}?${Date.now()}`}
            >
              {getInitials(user.first_name + ' ' + user.last_name)}
            </Avatar>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.first_name} ${user.last_name}`}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {user.role_name}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>
        <Divider />
        {/*  <Box p={2}>*/}
        {/*    <Settings />*/}
        {/*  </Box>*/}
        {/*<Divider />*/}
        <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/terms"
            >
              Check our Terms
            </Link>
          </Box>
        </Box>
        <Divider />
          <Box p={2}>
            <Button onClick={handleLogout} className={classes.logout}>
              <Typography color="error">Logout</Typography>
            </Button>
          </Box>
      </PerfectScrollbar>
    </Box>
  );

  if (!email_v || !golflink_v) {
    return null;
  }

  return (
    <>
      {/* <Hidden> */}
        <Drawer
          anchor="right"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant={temporaryNavbar ? 'temporary' : 'permanent'}
        >
          {content}
        </Drawer>
      {/* </Hidden> */}
      {/* <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden> */}
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
