/* eslint-disable react/no-array-index-key */
import React, {
  lazy,
  Suspense,
  Fragment
} from 'react';

import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';

import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import AdminGuard from 'src/components/AdminGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/home" />
  },
  {
    exact: true,
    path: '/403',
    component: lazy(() => import('src/views/pages/Error403View'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/verify-email',
    component: lazy(() => import('src/views/pages/VerifyEmail'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        //after login go here
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/competitions" />
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      // {
      //   exact: true,
      //   path: '/app/reports/dashboard',
      //   component: lazy(() => import('src/views/reports/DashboardView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/reports',
      //   component: () => <Redirect to="/app/reports/dashboard" />
      // },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/users/new',
        component: lazy(() => import('src/views/management/User/UserEditView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/users',
        component: lazy(() => import('src/views/management/User/UserListView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/users/:userId',
        component: lazy(() => import('src/views/management/User/UserDetailsView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/users/:userId/edit',
        component: lazy(() => import('src/views/management/User/UserEditView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/clubs/new',
        component: lazy(() => import('src/views/management/Club/ClubEditView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/clubs',
        component: lazy(() => import('src/views/management/Club/ClubListView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/clubs/:clubId',
        component: lazy(() => import('src/views/management/Club/ClubDetailsView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/clubs/:clubId/edit',
        component: lazy(() => import('src/views/management/Club/ClubEditView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/players/new',
        component: lazy(() => import('src/views/management/Player/PlayerEditView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/players',
        component: lazy(() => import('src/views/management/Player/PlayerListView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/players/:playerId',
        component: lazy(() => import('src/views/management/Player/PlayerDetailsView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/players/:playerId/edit',
        component: lazy(() => import('src/views/management/Player/PlayerEditView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/events/new',
        component: lazy(() => import('src/views/management/Event/EventEditView'))
      },
      {
        exact: true,
        path: '/app/events',
        component: lazy(() => import('src/views/management/Event/EventListView'))
      },
      {
        exact: true,
        path: '/app/events/:eventId',
        component: lazy(() => import('src/views/management/Event/EventDetailsView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/events/:eventId/edit',
        component: lazy(() => import('src/views/management/Event/EventEditView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/competitions/new',
        component: lazy(() => import('src/views/management/Competition/CompetitionEditView'))
      },
      {
        exact: true,
        path: '/app/competitions',
        component: lazy(() => import('src/views/management/Competition/CompetitionListView'))
      },
      {
        exact: true,
        path: '/app/competitions/:competitionId',
        component: lazy(() => import('src/views/management/Competition/CompetitionDetailsView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/competitions/new/event/:eventId/',
        component: lazy(() => import('src/views/management/Competition/CompetitionEditView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/competitions/:competitionId/clone/:cloneId',
        component: lazy(() => import('src/views/management/Competition/CompetitionEditView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/competitions/:competitionId/edit',
        component: lazy(() => import('src/views/management/Competition/CompetitionEditView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/teams/:competitionId',
        component: lazy(() => import('src/views/management/Competition/CompetitionTeamsView'))
      },
      {
        exact: true,
        path: '/app/competition/:competitionId/leaderboard',
        component: lazy(() => import('src/views/management/Competition/CompetitionLeaderboardView'))
      },
      {
        exact: true,
        path: '/app/leaderboard/legacy/:competitionId',
        component: lazy(() => import('src/views/management/Competition/CompetitionLeaderboardLegacyView'))
      },
      {
        exact: true,
        path: '/app/seasons',
        component: lazy(() => import('src/views/management/Season/SeasonListView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/seasons/new',
        component: lazy(() => import('src/views/management/Season/SeasonEditView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/seasons/:seasonId/edit',
        component: lazy(() => import('src/views/management/Season/SeasonEditView'))
      },
      {
        exact: true,
        path: '/app/seasons/:seasonId',
        component: lazy(() => import('src/views/management/Season/SeasonDetailsView'))
      },
      {
        exact: true,
        path: '/app/season/:seasonId/leaderboard',
        component: lazy(() => import('src/views/management/Season/SeasonLeaderboardView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      // {
      //   exact: true,
      //   path: '/pricing',
      //   component: lazy(() => import('src/views/pages/PricingView'))
      // },
      {
        exact: true,
        path: '/terms',
        component: lazy(() => import('src/views/pages/TermsView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
