/* eslint-disable no-param-reassign */
import produce from "immer";
import {
  COMPETITIONS_REQUEST,
  COMPETITIONS_SUCCESS,
  COMPETITIONS_FAILURE,
  GET_COMPETITION_REQUEST,
  GET_COMPETITION_SUCCESS,
  GET_COMPETITION_FAILURE,
  COMPETITIONS_AFTER_LOGIN,
  COMPETITIONS_UNMOUNT
} from 'src/actions/competitionsActions';


const initialState = {
  list: [],
  listLoading: false,
  selectedCompetition: null,
  selectedLoading: false,
  fetchCompetitions: true
};

const competitionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPETITIONS_REQUEST:
      return produce(state, (draft) => {
        draft.listLoading = true;
        draft.list = [];
      });
    case COMPETITIONS_SUCCESS:
      return produce(state, (draft) => {
        draft.listLoading = false;
        draft.list = action.payload;
      });
    case COMPETITIONS_FAILURE:
      // Maybe store error
      return produce(state, (draft) => {
        draft.listLoading = false;
        draft.list = [];
      });
    case GET_COMPETITION_REQUEST:
      return produce(state, (draft) => {
        draft.selectedCompetition = null;
        draft.selectedLoading = true;
      });
    case GET_COMPETITION_SUCCESS:
      return produce(state, (draft) => {
        draft.selectedCompetition = action.payload;
        draft.selectedLoading = false;
      });
    case GET_COMPETITION_FAILURE:
      // Maybe store error
      return produce(state, (draft) => {
        draft.selectedCompetition = null;
        draft.selectedLoading = false;
      });
    case COMPETITIONS_AFTER_LOGIN:
      return produce(state, (draft) => {
        draft.list = action.payload;
        draft.listLoading = false;
        draft.fetchCompetitions = false;
      });
    case COMPETITIONS_UNMOUNT:
      return produce(state, (draft) => {
        draft.fetchCompetitions = true;
      });
    default:
      return state
  }
};

export default competitionsReducer;
