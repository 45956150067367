import axios from "src/utils/axios";

const BASE_URL = '/api';

class BaseService {
  static id;
  static collection;

  static constructUrl(path) {
    let parts = [BASE_URL];
    if (this.collection) parts.push(this.collection);
    if (path) {
      if (path instanceof Array) parts = parts.concat(path);
      else parts.push(path.toString());
    }

    const url = parts.filter(Boolean).join('/');
    return url
  }

  constructUrl(path) {
    let parts = [this.id];
    if (path) {
      if (path instanceof Array) parts = parts.concat(path);
      else parts.push(path.toString());
    }
    const url = this.constructor.constructUrl(parts.filter(Boolean));

    return url;
  }
  constructor(id) {
    this.id = id;
  }

  static list(params = {}) {
    return axios.get(this.constructUrl(), {params});
  }

  static create(data) {
    return axios.post(this.constructUrl(), data);
  }

  get() {
    return axios.get(this.constructUrl());
  }

  update(data) {
    return axios.patch(this.constructUrl(), data);
  }
}

export default BaseService;
