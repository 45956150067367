import authService from 'src/services/authService';
import { competitionsUnmount, getCompetitionsAfterLogin } from './competitionsActions';
import UserService from 'src/services/userService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';

export function login(email, password, redirectCallback) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginWithEmailAndPassword(email, password);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
      if (user && user.email_v !== 0) {
        const competitions = await dispatch(getCompetitionsAfterLogin());
        const inProgressCompetition = competitions.find(competition => competition.in_progress);
        if (inProgressCompetition) dispatch(competitionsUnmount());
        if (redirectCallback) {
          const path = `/app/competition${inProgressCompetition ? '' : 's'}/${inProgressCompetition ? inProgressCompetition.id : ''}${inProgressCompetition ? `/leaderboard` : ''}`;
          redirectCallback(path);
        }
      }
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      user
    }
  });
}

export function logout() {
  return async (dispatch) => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export function register(values) {
  return async (dispatch) => {
    try {
      const user = await authService.register(values);
      // console.log('user', user)
      if (user) {
        dispatch({ type: REGISTER, payload: { user } });
      }
    } catch (error) {
      if (error) throw new Error(error.response.data.message);
    }
  };
}

export function updateProfile({id, ...update}) {
  const request = new UserService(id).update(update);
  // const request = axios.post('/api/account/profile', { update });

  return (dispatch) => {
    request.then((response) => dispatch({
      type: UPDATE_PROFILE,
      payload: response.data
    }));
  };
}
