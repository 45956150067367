import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { useIsEmailVerified } from 'src/utils/account';

function AuthGuard({ children }) {
  const account = useSelector((state) => state.account);
  const {email_v, golflink_v} = useIsEmailVerified();
  const location = useLocation();

  if (!account.user) {
    return <Redirect to="/login" />;
  }
  else if ((!email_v || !golflink_v) && location.pathname !== '/app/account') {
    return <Redirect to="/app/account" />;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
