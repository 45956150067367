import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 200,
    paddingBottom: 200,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    },
    border: '2px solid gray'
  }
}));

function Hero({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={5}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography
                variant="overline"
                color="secondary"
              >
                Introducing
              </Typography>
              <Typography
                variant="h1"
                color="textPrimary"
              >
                Virtual Pro-Am
              </Typography>
              <Box mt={3}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  An exciting, interactive golf competition enabling Club golfers to pair up with a touring Pro golfer in any of the world’s sanctioned tour events, to play for money either amongst golfing mates, OR within a more formally structured competition run via your home club Match Committee, possibly as an occasional (or regular) fundraiser.
                </Typography>
              </Box>
              {/*<Box mt={3}>*/}
              {/*  <Grid*/}
              {/*    container*/}
              {/*    spacing={3}*/}
              {/*  >*/}
              {/*    <Grid item>*/}
              {/*      <Typography*/}
              {/*        variant="h1"*/}
              {/*        color="secondary"*/}
              {/*      >*/}
              {/*        30+*/}
              {/*      </Typography>*/}
              {/*      <Typography*/}
              {/*        variant="overline"*/}
              {/*        color="textSecondary"*/}
              {/*      >*/}
              {/*        Demo Pages*/}
              {/*      </Typography>*/}
              {/*    </Grid>*/}
              {/*    <Grid item>*/}
              {/*      <Typography*/}
              {/*        variant="h1"*/}
              {/*        color="secondary"*/}
              {/*      >*/}
              {/*        UX*/}
              {/*      </Typography>*/}
              {/*      <Typography*/}
              {/*        variant="overline"*/}
              {/*        color="textSecondary"*/}
              {/*      >*/}
              {/*        Complete Flows*/}
              {/*      </Typography>*/}
              {/*    </Grid>*/}
              {/*    <Grid item>*/}
              {/*      <Typography*/}
              {/*        variant="h1"*/}
              {/*        color="secondary"*/}
              {/*      >*/}
              {/*        300+*/}
              {/*      </Typography>*/}
              {/*      <Typography*/}
              {/*        variant="overline"*/}
              {/*        color="textSecondary"*/}
              {/*      >*/}
              {/*        Components*/}
              {/*      </Typography>*/}
              {/*    </Grid>*/}
              {/*  </Grid>*/}
              {/*</Box>*/}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
          >
            <Box position="relative">
              <div className={classes.shape}>
                <iframe title="golf-preview" src="https://giphy.com/embed/C8XeIvBIBk6S7x0bGC" width="480" height="480" frameBorder="0"
                        className="giphy-embed" allowFullScreen></iframe>
                {/* <p><a href="https://giphy.com/gifs/travelerschamp-golf-pga-tour-C8XeIvBIBk6S7x0bGC"></a></p> */}
              </div>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
          >
            <Box position="relative">
              <div className={classes.image}>
                <img
                  alt="2021 Masters Winner PC's Cink"
                  src="/static/images/golf/green-jacket-masters-2021.jpeg"
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
